.heading-container {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    margin-bottom: 10%;
    background-color: rgba(255, 255, 255, 0.8);
}

.main-heading {
    padding-top: 5%;
    text-align: center;
    font-size: 60px;
    color: black;
}

.subtitle {
    text-align: center;
    font-size: 32px;
    color: black;
}

.bio-container {
    /* background-color: rgba(96, 172, 255, 0.5); */
    /* margin-top: 5%; */
    padding: 2%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.bio {
    text-align: center;
    font-size: 20px;
    color: black;
}

.header-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    max-width: 20%;
    min-width: 200px;
    border-radius: 8px;
}
