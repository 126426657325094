.about-me {
    background-color: rgba(255, 255, 255, 0.8);
    min-width: 60%;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 20px;
    color: black;
}

.about-main-header {
    font-size: 64px;
    text-align: center;
    display: block;
    margin-bottom: 12px;
}

.about-heading {
    padding-bottom: 1%;
    font-size: 32px;
    text-align: center;
}

.about-image {
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    display:block;
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}
