.contact-page {
    min-width: 60%;
    max-width: 80%;
    background-color: rgba(255, 255, 255, 0.8);
    margin-left: auto;
    margin-right: auto;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 20px;
    color: black;
}

.contact-header {
    font-size: 64px;
    text-align: center;
    display: block;
    margin-bottom: 12px;
}

.contact-form {
    text-align: center;
}

.contact-input {
    display: block;
    font-size: 20px;
    resize: none;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 0;
    max-width: 80%;
}

.contact-submit {
    font-size: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 0;
}

.thank-you {
    color: white;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    margin-bottom: 8px;
}

.contact-description {
    text-align: center;
    min-width: 60%;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: black;
    font-size: 20px;
    border-radius: 4px;
}
