.footer {
    /* background-color: dimgray;
    background-color: rgba(105, 105, 105, 0.6);
    opacity: 0.6;
    height: 44px;
    max-width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    bottom: 0;
    border-radius: 6px;
    z-index: 999; */
    /* position: sticky;
    left: 0;
    bottom: 0;
    right: 0; */
    margin-top: auto;
    background-color: rgba(0, 0, 0, 0.6);
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 25px 0;
  }

.buttons-container {
    display: block;
    margin-left: 0;
    margin-right: auto;
    text-align: center;
    width: 100%;
    margin-top: 1%;
    padding-bottom: 1%;
}

.social-media-buttons {
    display: inline;
    list-style-type: none;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.social-media-button {
    text-align: center;
    float: left;
    display: block;
    margin-right: 2%;
    margin-left: 2%;
    font-size: 18px;
}

.social-media-link {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: lightgray;
    text-decoration: none;
    border-radius: 4px;
}

.social-media-link:hover {
    background-color: white;
    color: darkslategray;
    transition: all 0.2s ease-out;
}
