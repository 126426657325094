* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Old Standard TT', serif;
  }

  .app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .home,
  .about,
  .contact {
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .about {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .contact {
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
  }
